import axios from "axios";
import { API_URL } from "@/const";

export default {
  data() {
    return {
      cbaItems: [],
      jwnetItems: [],
      routeInfo: {
        generateStoreInfo: {},
        jwnetItemTypeInfo: {},
        cbaItemInfo: {},
        constitutionItemInfo: [{}],
        wasteNameInfo: {},
        plansWasteUnitType: {},
        limitWasteUnitType: {},
        quantityConfirmBusinessType: {},
        shapeType: {},
        disposalWayType: {
          id: 0,
          name: ""
        },
        harmfulInfo: [
          {
            id: 0,
            name: ""
          }
        ],
        contactNoInfo: [],
        remarksInfo: [],
        routeCollectCompanyInfo: [
          {
            collectCompanyInfo: {
              id: 0,
              name: ""
            },
            collectFactoryInfo: {
              id: 0,
              name: "",
              jwnetId: ""
            },
            loadingPlaceList: [
              {
                licenceNo: 0,
                licenceMunicipalityName: "",
                licenceExpiredDate: "2021-08-11"
              }
            ],
            unloadingPlaceList: [
              {
                licenceNo: 0,
                licenceMunicipalityName: "",
                licenceExpiredDate: "2021-08-11"
              }
            ],
            destinationInfo: {
              destinationTypeInfo: {
                id: 0,
                name: ""
              },
              id: 0,
              name: "",
              jwnetId: "",
              storeCode: "",
              addressInfo: {
                zipCode: "",
                prefecturesId: 0,
                prefecturesName: "",
                address: "",
                buildingName: ""
              }
            },
            transshipmentJwnetItem: [
              {
                id: 0,
                name: ""
              }
            ]
          }
        ],
        collectUnitPriceList: [
          {
              sectionNo: 1,
              collectUnitPrice: 0,
              collectPriceWasteUnitTypeId: 0,
          }
        ],
        routeDisposalCompanyInfo: {
          disposalCompanyInfo: {},
          disposalLicenceInfo: [],
          destinationInfo: {
            destinationTypeInfo: {},
            addressInfo: {},
          },
          disposalJwnetItem: [],
        },
        labelInfo: [],
        wasteTypeInfo: {
          id: 0,
          name: ""
        },
        checkSheetInfo: {
          registerJwnet: false,
          generatorEmail:{
              mail:""
          },
          collectorEmail:{
              mail:""
          },
          disposerEmail:{
              mail:""
          },
          emails:[
              {email:""},
              {email:""},
              {email:""},
              {email:""},
              {email:""}
          ],
          qrInfos:[
              {qrInfo:""},
              {qrInfo:""}
          ]
        },
      },
      wasteUnitTypes: [],
      quantityConfirmMembers: [],
      shapeTypes: [],
      disposalWayTypes: [],
      harmfulTypes: [],
      manifestTypeInfo: {},
      wasteNameTypes: [],
      targetQuantityTypes: [],
    };
  },
  methods: {

    // CBA品目IDを変更すると廃棄物名称選択肢も変更する
    changeCbaItemTypeHandler() {
      if (this.resettable) this.formDuplicate.wasteNameId = null; // 廃棄物名称のリセット
      if (this.resettable) this.wasteNameTypes = []; // 廃棄物名称のプルダウンリセット
      if (!this.formDuplicate.cbaItemId) return;
      this.getWasteNameTypesByCbaItemId(this.formDuplicate.cbaItemId)
    },
 
    // CBA品目IDで廃棄物名称選択肢を取得
    getWasteNameTypesByCbaItemId(cbaItemId) {
      // CBA品目変更 DOMAIN.CBA_WASTE_NAME + {CBA品目ID}
      return axios
        .get(API_URL.DOMAIN.CBA_WASTE_NAME + cbaItemId)
        .then((res) => {
          this.wasteNameTypes = res.data.wasteNameList;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // 廃棄物種類別CBA品目選択肢取得API DOMAIN.CBA_JWNET_ITEMS + {廃棄物種類ID}
    getCbaItemsApi(jwnetItemTypeId) {
      return axios
      .get(API_URL.DOMAIN.CBA_JWNET_ITEMS + jwnetItemTypeId)
      .then((res) => {
        this.cbaItems = res.data.cbaItemList;
      })
      .catch(() => { });
    },

    routeDetailApi(routeId) {
      return axios
        .get(API_URL.ROUTE.DETAIL + routeId)
        .then((res) => {
          const data = res.data;
          this.routeInfo = data;

          this.isFavorite = data.isFavorite;
          this.formDuplicate.routeName = data.routeName;
          this.formDuplicate.detailWasteName = data.detailWasteName;
          this.formDuplicate.isChangeStore = data.isChangeStore;
          this.formDuplicate.jwnetItemTypeId = data.jwnetItemTypeInfo.id;
          this.formDuplicate.cbaItemId = data.cbaItemInfo.id;
          if (data.wasteNameInfo) {
            this.formDuplicate.wasteNameId = data.wasteNameInfo.id;
          }
          this.formDuplicate.plansQuantity = data.plansQuantity;
          if (data.plansWasteUnitType) {
            this.formDuplicate.plansWasteUnitTypeId = data.plansWasteUnitType.id;
          }
          this.formDuplicate.limitQuantity = data.limitQuantity;
          if (data.limitWasteUnitType) {
            this.formDuplicate.limitWasteUnitTypeId = data.limitWasteUnitType.id;
          }
          if (data.quantityConfirmBusinessType) {
            this.formDuplicate.quantityConfirmBusinessTypeId = data.quantityConfirmBusinessType.id;
          }
          if (data.shapeType) {
            this.formDuplicate.shapeTypeId = data.shapeType.id;
          }
          this.formDuplicate.shapeQuantity = data.shapeQuantity;
          if (data.disposalWayType) {
            this.formDuplicate.disposalWayTypeId = data.disposalWayType.id;
          }
          this.formDuplicate.harmfulInfo = [];
          // 有害物質リスト
          if (data.harmfulInfo.length) {
            data.harmfulInfo.forEach((elem) => {
              this.formDuplicate.harmfulInfo.push({ harmfulTypeId: elem.id });
            });
          }

          // 運搬単価
          this.formDuplicate.collectUnitPriceList = [];
          this.validate.collectUnitPriceList = [];
          if (data.collectUnitPriceList.length > 0) {
            data.collectUnitPriceList.forEach((c) => {
                this.formDuplicate.collectUnitPriceList.push({
                    sectionNo: c.sectionNo,
                    collectUnitPrice: c.collectUnitPrice,
                    collectPriceWasteUnitTypeId: c.collectPriceWasteUnitType ? c.collectPriceWasteUnitType.id : 0
                });
                this.validate.collectUnitPriceList.push({ isValid: true, errMsg: "" });
            });
          }

          this.formDuplicate.disposalUnitPrice = data.disposalUnitPrice;
          if (data.disposalPriceWasteUnitType) {
            this.formDuplicate.disposalPriceWasteUnitTypeId = data.disposalPriceWasteUnitType.id;
          }
          this.formDuplicate.valuablesUnitPrice = data.valuablesUnitPrice;
          this.formDuplicate.conversionFactorKg = data.conversionFactorKg;
          this.formDuplicate.conversionFactorM3 = data.conversionFactorM3;
          this.formDuplicate.conversionFactorLiter = data.conversionFactorLiter;
          this.formDuplicate.conversionFactorUnitNumber = data.conversionFactorUnitNumber;
          if (data.collectTargetQuantityTypeInfo) {
            this.formDuplicate.collectTargetQuantityTypeId = data.collectTargetQuantityTypeInfo.id;
          }
          if (data.disposalTargetQuantityTypeInfo) {
            this.formDuplicate.disposalTargetQuantityTypeId = data.disposalTargetQuantityTypeInfo.id;
          }

          this.formDuplicate.routeContactNoList = [];
          this.validate.routeContactNoList = [];
          data.contactNoInfo.forEach((elem) => {
            this.formDuplicate.routeContactNoList.push({ contactNo: elem.contactNo,});
            this.validate.routeContactNoList.push({ isValid: true, errMsg: "" });
          });
          while (this.formDuplicate.routeContactNoList.length < 3) {
            this.formDuplicate.routeContactNoList.push({ contactNo: "",})
            this.validate.routeContactNoList.push({ isValid: true, errMsg: "" });
          }

          this.formDuplicate.routeRemarksList = [];
          this.validate.routeRemarksList = [];
          data.remarksInfo.forEach((elem) => {
            this.formDuplicate.routeRemarksList.push({ id: elem.id, remarks: elem.remarks });
            this.validate.routeRemarksList.push({ isValid: true, errMsg: "" });
          });
          while (this.formDuplicate.routeRemarksList.length < 5) {
            this.formDuplicate.routeRemarksList.push({ id: null, remarks: "" });
            this.validate.routeRemarksList.push({ isValid: true, errMsg: "" });
          }

          this.formDuplicate.wdsFileName = data.wdsFileName;
          data.labelInfo.forEach((elem) =>
            this.formDuplicate.labelList.push({
              labelHeader: elem.labelHeader,
              labelTypeId: elem.labelTypeId,
              labelDefaultValue: elem.labelDefaultValue,
              labelUnit: elem.labelUnit,
            })
          );
          this.formDuplicate.isAutoApproval = data.isAutoApproval;
          this.formDuplicate.isAutoIssueAfterCollectionDate = data.isAutoIssueAfterCollectionDate;
          if (data.autoIssueExecDateType) this.formDuplicate.autoIssueExecDateTypeId = data.autoIssueExecDateType.id;
          if (data.autoIssueExecTime) this.formDuplicate.autoIssueExecTime = data.autoIssueExecTime;
          this.formDuplicate.isCollectReportAlert = data.isCollectReportAlert;
          this.formDuplicate.isAutoIssue = data.isAutoIssue;
          this.formDuplicate.isNecessityCommonCollectReport = data.isNecessityCommonCollectReport;
          this.formDuplicate.isDisposalReportAlert = data.isDisposalReportAlert;
          for (let i = 0; i < data.labelInfo.length; i++) {
            this.validate.labelList.push({
              labelHeader: { isValid: true, errMsg: "" },
              labelDefaultValue: { isValid: true, errMsg: "" },
              labelUnit: { isValid: true, errMsg: "" },
            });
          }

          // 担当者情報
          this.formDuplicate.registMemberName = data.registMemberName;
          this.validate.registMemberName = {};
          this.formDuplicate.generateMemberName = data.generateMemberName;
          this.validate.generateMemberName = {};
          this.formDuplicate.collectorRegistType = data.routeDeliveryConfirmation.collectorRegistType;

          // 受渡確認票の確認できる項目
          this.routeDeliveryConfirmation = data.routeDeliveryConfirmation;

          //最終処分場リスト
          this.finalDisposalSiteList = data.finalDisposalSiteList

          //予約・報告通知メール
          let notiMailList = []
          let notiMailValidateList = []
    
          notiMailList.push(data.notiMail1)
          notiMailValidateList.push({ isValid: true, errMsg: "" })
    
          notiMailList.push(data.notiMail2)
          notiMailValidateList.push({ isValid: true, errMsg: "" })
    
          notiMailList.push(data.notiMail3)
          notiMailValidateList.push({ isValid: true, errMsg: "" })

          this.formDuplicate.notiMailList = notiMailList
          this.validate.notiMailList = notiMailValidateList

          // 予約マニフェスト番号取得通知フラグ
          this.formDuplicate.isManifestNoRegistAlert = data.isManifestNoRegistAlert
          // 交付期限フラグ
          this.formDuplicate.isIssueDeadlineAlert = data.isIssueDeadlineAlert
        })
        .then(() => {
          // 廃棄物種類別CBA品目選択肢取得API DOMAIN.CBA_JWNET_ITEMS + {廃棄物種類ID}
          this.getCbaItemsApi(this.formDuplicate.jwnetItemTypeId)
        })
        .then(() => {
          // CBA品目変更 DOMAIN.CBA_WASTE_NAME + {CBA品目ID}
          this.getWasteNameTypesByCbaItemId(this.formDuplicate.cbaItemId)
        })
        .catch((err) => {
          if (err.response) {
            if (err.response.status === 404) {
                this.$router.push("/error/404");
                return;
            }
          }
          console.log(err);
        });
    },

    wasteUnitApi() {
      return axios
        .get(API_URL.DOMAIN.WASTE_UNIT)
        .then((res) => {
          this.wasteUnitTypes = res.data.wasteUnitTypes;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    quantityConfirmMembersApi(routeId) {
      return axios
        .get(`${API_URL.MANIFEST.CONFIRM_MEMBER}${routeId}`)
        .then((res) => {
          this.quantityConfirmMembers = res.data.quantityConfirmMembers;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // ルート荷姿選択肢取得API    API_URL.ROUTE.ROUTE_SHAPE_TYPE
    shapeTypesApi(routeId) {
      axios
        .get(API_URL.ROUTE.ROUTE_SHAPE_TYPE + routeId)
        .then((res) => {
          this.shapeTypes = res.data.shapeTypeList;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // ルート処分方法選択肢取得API    API_URL.ROUTE.ROUTE_DISPOSAL_WAY_TYPE
    disposalWayTypesApi(routeId) {
      axios
        .get(API_URL.ROUTE.ROUTE_DISPOSAL_WAY_TYPE + routeId)
        .then((res) => {
          this.disposalWayTypes = res.data.disposalWayTypeList;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // 有害物質名選択肢取得API    API_URL.DOMAIN.HARMFUL_TYPE
    getharmfulTypes() {
      axios
        .get(API_URL.DOMAIN.HARMFUL_TYPE)
        .then((res) => {
          this.harmfulTypes = res.data.harmfulTypeList;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // 廃棄物種類選択肢取得API
    getJwnetItemTypeApi() {
      return axios
        .get(API_URL.DOMAIN.JWNET_ITEM_TYPE)
        .then((res) => {
          this.jwnetItems = res.data.jwnetItems;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    targetQuantityTypesApi() {
        return axios
          .get(API_URL.DOMAIN.TARGET_QUANTITY_TYPE)
          .then((res) => {
            this.targetQuantityTypes = res.data.targetQuantityTypeList;
          })
          .catch((err) => {
            console.log(err);
          });
      },
  },
};
